body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

.flex-row-center {
  display: flex;
  flex-direction: row;
  align-items: center;
}

.horizontal-slider {
  height: 4rem;
  padding-top: 1.5rem;
  margin-top: 0.25rem;
  margin-bottom: 1rem;
}

.slider-track {
  height: 1rem;
}

.slider-track:nth-child(1) {
  background: #bbd5c7;
  margin-right: 3px;
}
  
.slider-track:nth-child(2) {
  background: rgb(233, 233, 233);
}

.slider-thumb {
  font-size: 1rem;
  background: lightblue;
  height: 4rem;
  width: 4rem;
  padding-top: 1.5rem;
  margin-top: -1.5rem;
  border-radius: 100%;
  text-align: center;
}